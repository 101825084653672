@mixin flexColumn($justify: null, $align: null) {
    display: flex;
    flex-direction: column;
    @if $justify {
        justify-content: $justify;
    }
    @if $align {
        align-items: $align;
    }
}
@mixin flexRow($justify: null, $align: null) {
    display: flex;
    flex-direction: row;
    @if $justify {
        justify-content: $justify;
    }
    @if $align {
        align-items: $align;
    }
}
@mixin font($size: null, $color: null, $weight: null, $lh: false) {
    @extend %font-main;
    @if $size {
        font-size: $size;
    }
    @if $color {
        color: $color;
    }
    @if $weight {
        font-weight: $weight;
    }
    @if $lh {
        line-height: $lh;
    }
}
@mixin media-mobile-xs {
    @media (max-width: 374px) {
        @content;
    }
}
