.help-drawer__close-icon {
    cursor: pointer;
    position: fixed;
    margin-top: -54px;
    right: 5px;
    color: white;
    opacity: 0.8;
    -webkit-tap-highlight-color: transparent;
    transition: 0.3s;
    filter: drop-shadow(0 0 2px rgba(0, 0, 0, 0.8));
    &:hover,
    &:active {
        opacity: 1;
    }
}
