.tip {
    @include flexRow(center, center);
    padding: 16px 0;
    gap: 10px;
    margin-left: -20px;
}
.tip__icon {
    color: $color-green;
}
.tip__link {
    color: $color-main-text;
    font-weight: 700;
    &:hover {
        text-decoration: none;
    }
}
.tip__tooltip {
    background-color: $tooltip-bg;
    border: none;
}
.tip__tooltip_list {
    margin: 0;
    padding: 8px 16px;
}
.tip__remark {
    font-size: 13px;
    font-style: italic;
    color: $color-main-text;
    font-weight: 300;
}
