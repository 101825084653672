.import__main {
    width: 100%;
    @include flexColumn(center, center);
}
.import__main-container {
    width: 100%;
    max-width: 1000px;
    padding: 10px;
    @include flexColumn(flex-start, center);
    color: #3e3d3d;
}
.import__title {
    font-size: 36px;
    color: $color-main-text;
    @include media-mobile-xs {
        font-size: 30px;
    }
}

.import__text {
    width: 100%;
    margin: 8px 0;
    line-height: 1.5;
    &_italic {
        font-style: italic;
    }
    &_accent {
        color: $color-main-dark;
        font-weight: 700;
    }
    &_accent-green {
        color: $color-green;
        font-weight: 700;
    }
    &_bold {
        font-weight: 700;
    }
    &_btn {
        font-style: italic;
        font-weight: 300;
        color: $color-main-dark;
    }
}
.import__list {
    margin: 10px 0;
    padding-left: 20px;
    ul {
        list-style-type: disc;
        padding-left: 30px;
        @include media-mobile-xs {
            padding-left: 20px;
        }
    }
}
.import__text-list-item {
    line-height: 1.5;
    margin-bottom: 10px;
}
.import__code {
    width: 80vw;
    padding: 5px;
    overflow: auto;
    overflow-y: hidden;
    font-weight: 700;
    background: #f8f8f8;
    border: 1px solid #d6d6d6;
}
.import__upload {
    @include flexColumn(flex-start, center);
    gap: 16px;
    min-width: 220px;
    margin-bottom: 16px;
}
.import__filename {
    max-width: 220px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.import__dialog-title {
    color: $color-main-text;
    font-weight: 700;
}
.import__result {
    margin-bottom: 16px;
}
.import__result-item {
    max-height: 100px;
    overflow: auto;
}
.import__result-title {
    font-weight: 700;
}
.import__btn {
    margin-bottom: 40px;
}
