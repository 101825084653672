.add-word__form {
    @include flexColumn(center, center);
}
.add-word {
    width: 100%;
    min-height: 100%;
    @include flexColumn(flex-start);
    // border: 1px solid $color-main-dark;
    // border-radius: 5px;
}
.add-word__word-wrapper {
    width: 100%;
    // margin-bottom: 20px;
    margin-bottom: clamp(0px, 100vh - 405px, 20px);
    padding: 10px;
    @include flexColumn(flex-start, flex-start);
    gap: 10px;
}
.add-word__divider {
    width: 100%;
    margin: 20px 0;
}

.add-word__side {
    font-size: 24px;
    width: 100%;
    margin: 10px 0;
    &.back {
        text-align: right;
    }
}
.add-word__textarea {
    width: 100%;
    border-color: #0000003b;
    border-radius: 4px;
    font-size: 16px;
    &:focus {
        outline: none;
        border: 2px solid $color-main-dark;
    }
}
