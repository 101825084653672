.activation {
    height: 100%;
    @include flexColumn(center, center);
    gap: 20px;
}
.activation__signin-link {
    font-size: 24px;
    color: $color-main-text;
    &:hover {
        text-decoration: none;
    }
}
