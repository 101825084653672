.signup {
    width: 100%;
    height: 100%;
    min-height: 570px;
    @include flexColumn(center, center);
    // border: 1px solid $color-main-dark;
    // border-radius: 5px;
}
.signup__wrapper {
    width: 260px;
    @include flexColumn;
    gap: 20px;
    margin-bottom: 20px;
}
.signup-form {
    @include flexColumn(center, center);
}
.signup__link {
    @extend %auth-link;
}
