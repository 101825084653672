.top-block {
    margin: 10px 5px 0 5px;
    // margin-bottom: clamp(0px, 100vh - 425px, 70px);
    @include flexColumn;
}
.top-block__cell {
    width: 100%;
    min-height: 60px;
    @include flexRow(space-between, center);
    margin: 0 5px;
}
.top-block__buttons {
    width: 100%;
    margin: 10px 5px;
    @include flexRow;
    gap: 5px;
}
.top-block__switcher-title {
    @include flexRow(center, center);
    margin-left: auto;
}
.top-block__settings-btn {
    background-color: #d6d6d617;
    border: 1px solid #0088d14d;
    cursor: pointer;
    border-radius: 50px;
    height: 48px;
    margin: 6px 6px;
    padding: 6px;
    transition: 0.2s;
    width: 48px;
    box-shadow: 0 0 3px 1px #0088d16e;
    -webkit-tap-highlight-color: transparent;
    z-index: 1250;
    &:disabled {
        opacity: 0;
    }

    &:hover {
        background-color: #ffffff;
        box-shadow: 0 0 6px 2px #0088d16e;
    }
    &:active {
        background-color: #b9ddf761;
        box-shadow: 0 0 3px 3px #0088d16e;
    }
    &.visible {
        transform: rotate(-180deg);
    }

    &.visited {
        background-color: #d6d6d617;
        box-shadow: 0 0 3px 1px #0088d16e;
    }
}
.top-block__tip {
    @include flexRow(flex-end, center);
    gap: 5px;
    min-width: 150px;
    flex-basis: 0;
    cursor: pointer;
    text-decoration: underline;
    &:hover {
        text-decoration: none;
    }
}
.top-block__dict-select {
    max-width: 150px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.top-block__dict-edit-field {
    max-width: 150px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.top-block__dict-name {
    border: 1px solid #c4c4c4;
    padding: 5px 14px;
    border-radius: 8px;
}
