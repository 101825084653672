.help {
    width: 100%;
    height: 100%;
    @include flexColumn(flex-start, center);
    // border: 1px solid $color-main-dark;
    // border-radius: 5px;
}
.help__header {
}
.help__main {
    max-width: 1000px;
    width: 100%;
    @include flexColumn(flex-start, center);
    padding: 10px;
    color: $color-text-dark;
}
.help__content-wrapper {
    @include flexColumn(flex-start, center);
}
.help__logo {
    width: 123px;
    margin-top: max(15px, 5vh);
}
.help__title {
    font-size: 36px;
    color: $color-main-text;
    @include media-mobile-xs {
        font-size: 30px;
    }
}
.help__header2 {
    @include media-mobile-xs {
        font-size: 22px;
    }
}
.help__header2_nav {
    text-align: center;
}
.help__quest-sign {
    color: $color-green;
}
.help__nav {
    color: $color-main-text;
    min-height: calc(100vh - 220px);
}
.help__nav-links-block {
    padding-left: 16px;
    li {
        margin: 6px 0;
    }
}
.help__link {
    color: $color-main-text;
    text-transform: uppercase;
    &_magic {
        color: $color-main-dark;
    }
    &:hover {
        text-decoration: none;
    }
}

.help__nav-divider {
    width: 100%;
    border: 0;
    border-top: 1px solid $color-main-text;
}
.help__section {
    width: 100%;
    @include flexColumn(flex-start, center);
}
.help__text {
    width: 100%;
    margin: 8px 0;
    line-height: 1.5;
    &_italic {
        font-style: italic;
    }
    &_accent {
        color: $color-main-dark;
        font-weight: 700;
    }
    &_accent-green {
        color: $color-green;
        font-weight: 700;
    }
    &_bold {
        font-weight: 700;
    }
    &_btn {
        font-style: italic;
        font-weight: 300;
        color: $color-main-dark;
    }
}
.help__goal-icon {
    font-size: 32px;
}
.help__text-list {
    width: 100%;
}
.help__text-list-item {
    line-height: 1.5;
    margin-bottom: 32px;
}
.help__text-icon-block {
    white-space: nowrap;
}
.help__text-icon {
    color: $color-green;
}
.help__del-icon {
    vertical-align: middle;
    margin-bottom: 2px;
}
.help__text-link {
    color: $color-main-dark;
    &:hover {
        text-decoration: none;
    }
}
.help__rechi-word {
    color: $color-main-dark;
    font-weight: bold;
    font-size: 18px;
}
