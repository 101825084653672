.learn {
    width: 100%;
}
.learn__main-block {
    margin-top: clamp(0px, 100vh - 600px, 70px);
    @include flexColumn(center, center);
    overflow: hidden;
}
.learn__words-block {
    width: 100%;
    margin-bottom: 80px;
    // min-height: 200px;
    // margin-bottom: auto;
    // @include flexColumn(space-between);
    // overflow: hidden;
}
.learn__word {
    width: 100%;
    min-height: 100%;
    @include flexColumn(center, center);
    font-size: 24px;
    font-weight: 700;
    color: $color-main-text;
}
.learn__context {
    font-size: 14px;
    font-style: italic;
    text-align: justify;
    padding: 10px;
    &.word {
        color: $color-main-text;
    }
    &.translate {
        color: $color-main-dark;
    }
}

.learn__translate {
    width: 100%;
    min-height: 100%;
    @include flexColumn(center, center);
    font-size: 24px;
    font-weight: 700;
    color: $color-main-dark;
}
.learn__divider {
    width: 100%;
    height: 1px;
    margin: 20px 0;
    border-radius: 50px;
    background-color: $color-main-dark;
}
.learn__button-block {
    position: fixed;
    bottom: 40px;
}
.learn__no-words {
    @include flexColumn(center, center);
    font-size: 18px;
    color: $color-main-text;
    text-align: center;
}
.bold {
    font-weight: 700;
}
.learn__tip {
    @include flexRow(flex-start, center);
    margin-bottom: 16px;
    margin-left: 4px;
    color: $color-main-text;
    font-weight: 700;
}
