.logo {
    width: 123px;
    position: absolute;
    top: clamp(15px, 30vh - 185px, 10vh);
}
.signin {
    width: 100%;
    height: 100%;
    min-height: 380px;
    @include flexColumn(center, center);
    // border: 1px solid $color-main-dark;
    // border-radius: 5px;
}
.signin__wrapper {
    width: 260px;
    @include flexColumn;
    gap: 20px;
    margin-bottom: 20px;
}
.signin-form {
    @include flexColumn(center, center);
}
.signin__link {
    @extend %auth-link;
}
