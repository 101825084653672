@font-face {
    font-family: "Roboto";
    src: local("Roboto"), url("../../assets/fonts/Roboto-Regular.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
    font-display: block;
}
@font-face {
    font-family: "Roboto";
    src: local("Roboto"), url("../../assets/fonts/Roboto-Bold.ttf") format("truetype");
    font-weight: bold;
    font-style: normal;
    font-display: block;
}
@font-face {
    font-family: "Roboto";
    src: local("Roboto"), url("../../assets/fonts/Roboto-Light.ttf") format("truetype");
    font-weight: 300;
    font-style: normal;
    font-display: block;
}
@font-face {
    font-family: "Roboto";
    src: local("Roboto"), url("../../assets/fonts/Roboto-Thin.ttf") format("truetype");
    font-weight: 100;
    font-style: normal;
    font-display: block;
}