.add-dict-btn {
    color: $color-main-dark;
}
.edit-dict-btn {
    color: $color-main-dark;
}
.save-dict-btn {
    color: $color-main-dark;
}
.fab-add-word-btn {
    position: fixed;
    bottom: 60px;
    right: 20px;
    z-index: 2;
}
.fab-go-top-btn {
    @include flexRow(center, center);
    position: sticky;
    width: 40px;
    height: 40px;
    bottom: 40px;
    right: 20px;
    align-self: flex-end;
    border: 1px solid;
    border-radius: 50px;
    background-color: white;
    cursor: pointer;
    opacity: 0.5;
    z-index: 2;
}
