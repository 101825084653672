.dictionary {
    width: 100%;
    min-height: 100%;
    @include flexColumn(flex-start);
    // border: 1px solid $color-main-dark;
    // border-radius: 5px;
}
.dictionary-main {
    @include flexColumn(center, center);
}
.dictionary__empty {
    width: 100%;
    height: 100%;
    margin-top: clamp(0px, 100vh - 425px, 70px);
    @include flexColumn(center, center);
    font-size: 18px;
    color: $color-main-text;
    text-align: center;
}
.dictionary__tip {
    @include flexRow(flex-start, center);
    margin-left: 10px;
    margin-top: 10px;
    color: $color-main-text;
    font-weight: 700;
}
