* {
    box-sizing: border-box;
}

body {
    font-family: Roboto, Arial, Helvetica, sans-serif;
    margin: 0;
}
.app {
    position: absolute;
    height: 100%;
    width: 100%;
    padding: 5px;
}
